import { doc, setDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { db } from '../../firebase';
import { logger } from '../../utils/logger';

interface ProfileData {
  firstName: string;
  lastName: string;
}

export async function createInitialUserProfile(user: User, profile: ProfileData): Promise<void> {
  try {
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      email: user.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      createdAt: new Date()
    }, { merge: true });
    
    logger.info('Initial user profile created:', { uid: user.uid });
  } catch (error) {
    logger.error('Error creating initial user profile:', error);
    throw error;
  }
}