import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, HelpCircle, ArrowRight } from 'lucide-react';
import Button from '../Button';
import { Exercise } from '../../types/tutor';

interface ExerciseSectionProps {
  exercises: Exercise[];
  onComplete: () => void;
}

export default function ExerciseSection({ exercises, onComplete }: ExerciseSectionProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  
  const currentExercise = exercises[currentIndex];

  const handleNext = () => {
    if (currentIndex === exercises.length - 1) {
      onComplete();
    } else {
      setCurrentIndex(prev => prev + 1);
      setShowAnswer(false);
      setUserAnswer('');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold">Exercise {currentIndex + 1} of {exercises.length}</h3>
          <span className="text-sm text-gray-500 capitalize">{currentExercise.type}</span>
        </div>

        <p className="text-lg mb-4">{currentExercise.question}</p>

        <div className="space-y-4">
          <textarea
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Enter your answer..."
            className="w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500"
            rows={3}
          />

          {currentExercise.hint && !showAnswer && (
            <button
              onClick={() => setShowAnswer(true)}
              className="flex items-center text-blue-600 hover:text-blue-700"
            >
              <HelpCircle className="w-4 h-4 mr-1" />
              Show Hint
            </button>
          )}

          {showAnswer && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              className="bg-green-50 p-4 rounded-lg"
            >
              <div className="flex items-start">
                <CheckCircle className="w-5 h-5 text-green-600 mr-2 mt-0.5" />
                <div>
                  <div className="font-medium text-green-800">Correct Answer:</div>
                  <p className="text-green-700 mt-1">{currentExercise.answer}</p>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          variant="outline"
          onClick={() => setShowAnswer(true)}
          disabled={showAnswer}
        >
          Check Answer
        </Button>
        <Button
          onClick={handleNext}
          disabled={!showAnswer}
          className="flex items-center"
        >
          {currentIndex === exercises.length - 1 ? 'Complete' : (
            <>
              Next Exercise
              <ArrowRight className="w-4 h-4 ml-2" />
            </>
          )}
        </Button>
      </div>
    </motion.div>
  );
}