import { logger } from '../../utils/logger';

export function validateOpenAIKey(apiKey: string | undefined): void {
  if (!apiKey) {
    const error = 'OpenAI API key is not configured. Please add VITE_OPENAI_API_KEY to your .env file.';
    logger.error(error);
    throw new Error(error);
  }

  if (!apiKey.startsWith('sk-') || apiKey.length < 40) {
    const error = 'Invalid OpenAI API key format. Please check your configuration.';
    logger.error(error);
    throw new Error(error);
  }
}