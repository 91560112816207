import { generateCompletion } from '../openai.service';
import { StudyPlan, Exercise } from '../../../types/tutor';
import { logger } from '../../utils/logger';

export async function generateStudyPlan(topic: string, content?: string): Promise<StudyPlan> {
  try {
    const prompt = `Create a comprehensive study plan for ${topic}. Include:
    1. 3-5 key sections with titles, content, and key points
    2. 5-10 interactive exercises with questions and answers
    3. Estimated completion time in minutes
    
    Format as JSON with this structure:
    {
      "sections": [
        {
          "title": "string",
          "content": "string",
          "keyPoints": ["string"]
        }
      ],
      "exercises": [
        {
          "type": "flashcard" | "fillBlank" | "shortAnswer",
          "question": "string",
          "answer": "string",
          "hint": "string"
        }
      ],
      "estimatedTime": number
    }`;

    const response = await generateCompletion(prompt);
    const plan = JSON.parse(response || '{}');
    
    // Ensure exercises array exists and has content
    const exercises: Exercise[] = plan.exercises?.map((exercise: any) => ({
      type: exercise.type || 'shortAnswer',
      question: exercise.question || '',
      answer: exercise.answer || '',
      hint: exercise.hint
    })) || [];

    return {
      topic,
      sections: plan.sections || [],
      exercises: exercises,
      estimatedTime: plan.estimatedTime || 30
    };
  } catch (error) {
    logger.error('Error generating study plan:', error);
    throw new Error('Failed to generate study plan');
  }
}