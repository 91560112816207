import { getOpenAIClient } from './client';
import { handleOpenAIError } from './error-handler';
import { logger } from '../../utils/logger';

export async function generateCompletion(prompt: string): Promise<string | null> {
  try {
    logger.debug('Generating completion for prompt');
    
    const openai = getOpenAIClient();
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are a quiz generator. Generate questions in valid JSON format only, with no additional text or markdown."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
      response_format: { type: "json_object" }
    });

    return completion.choices[0].message.content;
  } catch (error: any) {
    throw handleOpenAIError(error);
  }
}