import { StateCreator } from 'zustand';
import { AuthState } from '../types';

export const createAuthSlice: StateCreator<AuthState> = (set) => ({
  user: null,
  profile: null,
  loading: false,
  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (loading) => set({ loading })
});