import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  User,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { auth } from '../../firebase';
import { logger } from '../../utils/logger';
import { captureError } from '../../monitoring/sentry';
import { performanceMonitor } from '../../monitoring/performance';
import { createInitialUserProfile } from './profile.service';
import { useQuizStore } from '../../store';
import { getAuthErrorMessage } from './error-handler';

// Initialize persistence after auth is ready
async function initializeAuthPersistence() {
  try {
    if (auth) {
      await setPersistence(auth, browserLocalPersistence);
      logger.debug('Auth persistence set to LOCAL');
    }
  } catch (error) {
    logger.error('Error setting auth persistence:', error);
  }
}

initializeAuthPersistence();

export async function signIn(email: string, password: string): Promise<User> {
  return performanceMonitor.measureAsync('auth_sign_in', async () => {
    try {
      logger.debug('Attempting to sign in user:', { email });
      const result = await signInWithEmailAndPassword(auth, email, password);
      logger.info('User signed in successfully:', { uid: result.user.uid });
      return result.user;
    } catch (error: any) {
      logger.error('Error signing in user:', error);
      captureError(error);
      throw new Error(getAuthErrorMessage(error.code));
    }
  });
}

export async function signUp(email: string, password: string, firstName: string, lastName: string): Promise<User> {
  return performanceMonitor.measureAsync('auth_sign_up', async () => {
    try {
      logger.debug('Attempting to create new user:', { email });
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      await createInitialUserProfile(result.user, { firstName, lastName });

      // Initialize quiz store for new user
      useQuizStore.getState().resetStore();
      
      logger.info('User created successfully:', { uid: result.user.uid });
      return result.user;
    } catch (error: any) {
      logger.error('Error creating new user:', error);
      captureError(error);
      throw new Error(getAuthErrorMessage(error.code));
    }
  });
}

export async function signOut(): Promise<void> {
  try {
    logger.debug('Attempting to sign out user');
    
    // Clear the quiz store before signing out
    useQuizStore.getState().resetStore();
    
    // Clear localStorage
    localStorage.clear();
    
    await firebaseSignOut(auth);
    logger.info('User signed out successfully');
  } catch (error: any) {
    logger.error('Error signing out user:', error);
    captureError(error);
    throw new Error('Failed to sign out');
  }
}