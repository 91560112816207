import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock, BookOpen, Brain, CheckCircle, ChevronDown, ChevronUp } from 'lucide-react';
import Button from '../Button';
import { StudyPlan as StudyPlanType } from '../../types/tutor';

interface StudyPlanProps {
  plan: StudyPlanType;
  onStart: () => void;
}

export default function StudyPlan({ plan, onStart }: StudyPlanProps) {
  const [showAllExercises, setShowAllExercises] = useState(false);

  const displayedExercises = showAllExercises 
    ? plan.exercises 
    : plan.exercises.slice(0, 3);

  const remainingExercises = plan.exercises.length - 3;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Study Plan: {plan.topic}</h2>
        <div className="flex items-center text-gray-600">
          <Clock className="w-5 h-5 mr-2" />
          <span>~{plan.estimatedTime} minutes</span>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <h3 className="font-semibold flex items-center">
            <BookOpen className="w-5 h-5 mr-2 text-blue-600" />
            Key Concepts
          </h3>
          <ul className="space-y-3">
            {plan.sections.map((section, index) => (
              <li key={index} className="bg-white p-4 rounded-lg shadow">
                <h4 className="font-medium mb-2">{section.title}</h4>
                <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                  {section.keyPoints.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-4">
          <h3 className="font-semibold flex items-center">
            <Brain className="w-5 h-5 mr-2 text-blue-600" />
            Practice Exercises
          </h3>
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <p className="text-blue-800 flex items-center">
                <CheckCircle className="w-4 h-4 mr-2" />
                {plan.exercises.length} interactive exercises included
              </p>
              <span className="text-sm text-blue-600">
                {plan.exercises.map(ex => ex.type).filter((v, i, a) => a.indexOf(v) === i).join(', ')}
              </span>
            </div>
            <ul className="mt-3 space-y-2">
              <AnimatePresence>
                {displayedExercises.map((exercise, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="text-sm text-blue-700"
                  >
                    • {exercise.question}
                  </motion.li>
                ))}
              </AnimatePresence>
            </ul>
            {remainingExercises > 0 && (
              <button
                onClick={() => setShowAllExercises(!showAllExercises)}
                className="mt-3 text-sm text-blue-600 hover:text-blue-700 flex items-center"
              >
                {showAllExercises ? (
                  <>
                    Show less
                    <ChevronUp className="w-4 h-4 ml-1" />
                  </>
                ) : (
                  <>
                    + {remainingExercises} more exercises...
                    <ChevronDown className="w-4 h-4 ml-1" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      <Button 
        onClick={onStart} 
        className="w-full md:w-auto"
        disabled={plan.exercises.length === 0}
      >
        Start Learning
      </Button>
    </motion.div>
  );
}