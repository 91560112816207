import { StateCreator } from 'zustand';
import { QuizState } from '../types';
import { validateAnswer } from '../../utils/answer-validation';

export const createQuizSlice: StateCreator<QuizState> = (set, get) => ({
  questions: [],
  currentQuestionIndex: 0,
  userAnswers: {},
  hintsUsed: {},
  quizStartTime: null,
  quizHistory: [],
  monthlyQuizCount: 0,
  lastMonthReset: null,
  quizSettings: null,

  setQuestions: (questions) => set({ 
    questions,
    currentQuestionIndex: 0,
    userAnswers: {},
    hintsUsed: {},
    quizStartTime: Date.now()
  }),

  setCurrentQuestionIndex: (index) => set({ currentQuestionIndex: index }),

  setUserAnswer: (questionId, answer) => set((state) => ({
    userAnswers: { ...state.userAnswers, [questionId]: answer }
  })),

  useHint: (questionId) => set((state) => ({
    hintsUsed: { ...state.hintsUsed, [questionId]: true }
  })),

  addToHistory: (result) => set((state) => ({
    quizHistory: [result, ...state.quizHistory].slice(0, 50)
  })),

  incrementQuizCount: () => set((state) => {
    const now = new Date();
    const lastReset = state.lastMonthReset ? new Date(state.lastMonthReset) : null;
    
    if (!lastReset || lastReset.getMonth() !== now.getMonth()) {
      return {
        monthlyQuizCount: 1,
        lastMonthReset: now.toISOString()
      };
    }

    return {
      monthlyQuizCount: state.monthlyQuizCount + 1
    };
  }),

  checkAndResetMonthlyCount: () => set((state) => {
    const now = new Date();
    const lastReset = state.lastMonthReset ? new Date(state.lastMonthReset) : null;
    
    if (!lastReset || lastReset.getMonth() !== now.getMonth()) {
      return {
        monthlyQuizCount: 0,
        lastMonthReset: now.toISOString()
      };
    }
    return state;
  }),

  resetStore: () => set({
    questions: [],
    currentQuestionIndex: 0,
    userAnswers: {},
    hintsUsed: {},
    quizStartTime: null,
    quizHistory: [],
    monthlyQuizCount: 0,
    lastMonthReset: null,
    quizSettings: null
  }),

  setQuizSettings: (settings) => set({ quizSettings: settings }),

  calculateScore: () => {
    const state = get();
    let correct = 0;
    const total = state.questions.length;
    
    state.questions.forEach((question) => {
      if (validateAnswer(
        question.type,
        state.userAnswers[question.id],
        question.correctAnswer
      )) {
        correct++;
      }
    });

    const timeSpent = state.quizStartTime 
      ? Math.floor((Date.now() - state.quizStartTime) / 1000)
      : 0;

    return {
      correct,
      total,
      percentage: Math.round((correct / total) * 100),
      timeSpent
    };
  }
});