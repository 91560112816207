import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { logger } from './utils/logger';
import { firebaseConfig } from './config/firebase.config';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize Analytics conditionally
let analytics = null;
isSupported().then(supported => {
  if (supported) {
    analytics = getAnalytics(app);
    logger.info('Firebase Analytics initialized');
  }
}).catch(error => {
  logger.error('Error initializing Firebase Analytics:', error);
});

export { app, auth, db, analytics };