import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Upload, Brain, MessageSquare } from 'lucide-react';
import Button from '../components/Button';
import DocumentUpload from '../components/quiz/DocumentUpload';
import StudyPlan from '../components/tutor/StudyPlan';
import StudySession from '../components/tutor/StudySession';
import { generateStudyPlan } from '../lib/services/tutor/study-plan.service';
import { useTutorStore } from '../lib/store/tutor.store';
import toast from 'react-hot-toast';

export default function TutorMode() {
  const { currentStep, studyPlan, setCurrentStep, setStudyPlan } = useTutorStore();
  const [file, setFile] = useState<File | null>(null);
  const [topic, setTopic] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!topic && !file) return;

    setLoading(true);
    try {
      let content = topic;
      if (file) {
        // TODO: Extract content from file
        content = `${topic} ${file.name}`;
      }

      const plan = await generateStudyPlan(content);
      setStudyPlan(plan);
      setCurrentStep('plan');
    } catch (error) {
      console.error('Error generating study plan:', error);
      toast.error('Failed to generate study plan');
    } finally {
      setLoading(false);
    }
  };

  const handleStartLearning = () => {
    setCurrentStep('study');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-20 pb-12">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-8">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex justify-center mb-4"
            >
              <Brain className="w-12 h-12 text-blue-600" />
            </motion.div>
            <h1 className="text-4xl font-bold mb-4">AI Tutor Mode</h1>
            <p className="text-xl text-gray-600">
              Get personalized learning assistance powered by AI
            </p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-xl p-8"
          >
            {currentStep === 'input' && (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-4">
                    What would you like to learn about?
                  </label>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Enter a Topic
                        </label>
                        <input
                          type="text"
                          value={topic}
                          onChange={(e) => setTopic(e.target.value)}
                          className="w-full px-4 py-2 border rounded-xl focus:ring-2 focus:ring-blue-500"
                          placeholder="e.g., World War II, Python Programming..."
                        />
                      </div>
                      <div className="flex items-center">
                        <div className="flex-grow border-t border-gray-200"></div>
                        <span className="px-4 text-gray-500">or</span>
                        <div className="flex-grow border-t border-gray-200"></div>
                      </div>
                      <DocumentUpload
                        file={file}
                        onFileChange={setFile}
                        isLoading={loading}
                      />
                    </div>
                    <div className="bg-blue-50 rounded-xl p-6">
                      <h3 className="font-semibold mb-4 flex items-center">
                        <MessageSquare className="w-5 h-5 mr-2 text-blue-600" />
                        How it works
                      </h3>
                      <ul className="space-y-3 text-sm text-gray-600">
                        <li className="flex items-start">
                          <BookOpen className="w-4 h-4 mr-2 mt-0.5 text-blue-600" />
                          Enter a topic or upload study materials
                        </li>
                        <li className="flex items-start">
                          <Brain className="w-4 h-4 mr-2 mt-0.5 text-blue-600" />
                          AI generates a personalized study plan
                        </li>
                        <li className="flex items-start">
                          <Upload className="w-4 h-4 mr-2 mt-0.5 text-blue-600" />
                          Complete interactive exercises and quizzes
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={loading || (!topic && !file)}
                  className="w-full md:w-auto"
                >
                  {loading ? 'Creating Study Plan...' : 'Start Learning'}
                </Button>
              </form>
            )}

            {currentStep === 'plan' && studyPlan && (
              <StudyPlan plan={studyPlan} onStart={handleStartLearning} />
            )}

            {currentStep === 'study' && studyPlan && (
              <StudySession plan={studyPlan} />
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
}