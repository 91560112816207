import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Brain } from 'lucide-react';
import { StudyPlan } from '../../types/tutor';
import Button from '../Button';
import AIChat from './AIChat';
import ExerciseSection from './ExerciseSection';

interface StudySessionProps {
  plan: StudyPlan;
}

export default function StudySession({ plan }: StudySessionProps) {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showExercises, setShowExercises] = useState(false);

  // Validate that plan and sections exist
  if (!plan?.sections?.length) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600">No study plan available. Please try again.</p>
      </div>
    );
  }

  const currentSection = plan.sections[currentSectionIndex];

  if (showExercises) {
    return (
      <div className="grid md:grid-cols-2 gap-6">
        <ExerciseSection 
          exercises={plan.exercises}
          onComplete={() => setShowExercises(false)}
        />
        <AIChat topic={plan.topic} />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="grid md:grid-cols-2 gap-6"
    >
      <div className="space-y-6">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h2 className="text-xl font-bold mb-4 flex items-center">
            <BookOpen className="w-5 h-5 mr-2 text-blue-600" />
            {currentSection.title}
          </h2>
          <div className="prose prose-blue">
            {currentSection.content}
          </div>
          {currentSection.keyPoints?.length > 0 && (
            <div className="mt-4 pt-4 border-t">
              <h3 className="font-medium mb-2">Key Points:</h3>
              <ul className="space-y-2">
                {currentSection.keyPoints.map((point, index) => (
                  <li key={index} className="flex items-start">
                    <Brain className="w-4 h-4 mr-2 mt-1 text-blue-600" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="flex justify-between">
          {plan.sections.length > 1 && (
            <>
              <Button
                variant="outline"
                onClick={() => setCurrentSectionIndex(i => Math.max(0, i - 1))}
                disabled={currentSectionIndex === 0}
              >
                Previous Section
              </Button>
              <Button
                onClick={() => setCurrentSectionIndex(i => Math.min(plan.sections.length - 1, i + 1))}
                disabled={currentSectionIndex === plan.sections.length - 1}
              >
                Next Section
              </Button>
            </>
          )}
        </div>

        {currentSectionIndex === plan.sections.length - 1 && (
          <Button
            onClick={() => setShowExercises(true)}
            className="w-full"
          >
            Start Exercises
          </Button>
        )}
      </div>

      <div>
        <AIChat topic={plan.topic} />
      </div>
    </motion.div>
  );
}