import { generateCompletion } from '../openai.service';
import { logger } from '../../utils/logger';

export async function generateTutorResponse(
  question: string,
  context: string
): Promise<string> {
  try {
    const prompt = `As a knowledgeable tutor helping a student learn about ${context}, 
    provide a clear and helpful response to their question: "${question}"`;

    const response = await generateCompletion(prompt);
    return response || 'I apologize, but I am unable to provide a response at the moment.';
  } catch (error) {
    logger.error('Error generating tutor response:', error);
    throw new Error('Failed to generate response');
  }
}