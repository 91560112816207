import React, { useState } from 'react';
import { Info } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Button from '../Button';
import DocumentUpload from './DocumentUpload';
import { QuizSettings } from '../../types/quiz';
import { generateQuestions } from '../../lib/api';
import { useQuizStore } from '../../lib/store';
import { extractTextFromDocument } from '../../lib/services/document/document.service';

export default function QuizCreationForm() {
  const navigate = useNavigate();
  const { setQuestions, setQuizSettings } = useQuizStore();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<QuizSettings>({
    topic: '',
    questionType: 'multiple-choice',
    difficulty: 'intermediate',
    numberOfQuestions: 10,
  });
  const [documentFile, setDocumentFile] = useState<File | null>(null);
  const [method, setMethod] = useState<'topic' | 'document' | null>(null);

  const handleNumberChange = (value: number) => {
    if (value >= 5 && value <= 50) {
      setSettings({ ...settings, numberOfQuestions: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!method) {
      toast.error('Please choose a quiz creation method');
      return;
    }

    if (method === 'topic' && !settings.topic) {
      toast.error('Please enter a topic');
      return;
    }

    if (method === 'document' && !documentFile) {
      toast.error('Please upload a document');
      return;
    }

    setLoading(true);
    try {
      let content = settings.topic;

      if (method === 'document' && documentFile) {
        const docResult = await extractTextFromDocument(documentFile);
        content = docResult.text;
      }

      const questions = await generateQuestions({
        ...settings,
        topic: content
      });

      setQuizSettings(settings);
      setQuestions(questions);
      navigate('/quiz');
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to generate quiz');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Choose Your Quiz Source</h2>
        <p className="text-gray-600">Select how you want to create your quiz:</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <button
            onClick={() => setMethod('topic')}
            className={`p-4 border rounded-lg text-left transition-colors ${
              method === 'topic' 
                ? 'border-blue-500 bg-blue-50' 
                : 'hover:border-gray-300'
            }`}
          >
            <h3 className="font-semibold mb-2">Enter a Topic</h3>
            <p className="text-sm text-gray-600">
              Let our AI generate questions about any subject you want to learn
            </p>
          </button>

          <button
            onClick={() => setMethod('document')}
            className={`p-4 border rounded-lg text-left transition-colors ${
              method === 'document' 
                ? 'border-blue-500 bg-blue-50' 
                : 'hover:border-gray-300'
            }`}
          >
            <h3 className="font-semibold mb-2">Upload Study Material</h3>
            <p className="text-sm text-gray-600">
              Generate questions from your PDF or Word document
            </p>
          </button>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {method === 'topic' && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Topic or Subject
            </label>
            <input
              type="text"
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
              placeholder="e.g., World War II, Basic Mathematics, Solar System..."
              value={settings.topic}
              onChange={(e) => setSettings({ ...settings, topic: e.target.value })}
            />
            <p className="mt-1 text-sm text-gray-500 flex items-center">
              <Info className="w-4 h-4 mr-1" />
              Be specific for better results
            </p>
          </div>
        )}

        {method === 'document' && (
          <DocumentUpload
            file={documentFile}
            onFileChange={setDocumentFile}
            isLoading={loading}
          />
        )}

        {method && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Question Type
                </label>
                <select
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  value={settings.questionType}
                  onChange={(e) => setSettings({ ...settings, questionType: e.target.value as any })}
                >
                  <option value="multiple-choice">Multiple Choice</option>
                  <option value="true-false">True/False</option>
                  <option value="short-answer">Short Answer</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Difficulty Level
                </label>
                <select
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                  value={settings.difficulty}
                  onChange={(e) => setSettings({ ...settings, difficulty: e.target.value as any })}
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Number of Questions
              </label>
              <div className="flex items-center space-x-4">
                <button
                  type="button"
                  onClick={() => handleNumberChange(settings.numberOfQuestions - 1)}
                  className="w-12 h-12 flex items-center justify-center text-2xl font-bold border rounded-lg hover:bg-gray-50"
                  disabled={settings.numberOfQuestions <= 5}
                >
                  -
                </button>
                <div className="flex-1">
                  <input
                    type="number"
                    min="5"
                    max="50"
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 text-center text-lg"
                    value={settings.numberOfQuestions}
                    onChange={(e) => handleNumberChange(parseInt(e.target.value, 10))}
                  />
                  <p className="mt-1 text-sm text-gray-500 text-center">
                    Choose between 5 and 50 questions
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => handleNumberChange(settings.numberOfQuestions + 1)}
                  className="w-12 h-12 flex items-center justify-center text-2xl font-bold border rounded-lg hover:bg-gray-50"
                  disabled={settings.numberOfQuestions >= 50}
                >
                  +
                </button>
              </div>
            </div>

            <Button type="submit" size="lg" className="w-full" disabled={loading}>
              {loading ? 'Generating Quiz...' : 'Generate Quiz'}
            </Button>
          </>
        )}
      </form>
    </div>
  );
}