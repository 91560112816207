import { StateCreator } from 'zustand';
import { TutorState } from '../types';

export const createTutorSlice: StateCreator<TutorState> = (set) => ({
  currentStep: 'input',
  studyPlan: null,
  progress: 0,
  setCurrentStep: (step) => set({ currentStep: step }),
  setStudyPlan: (plan) => set({ studyPlan: plan }),
  updateProgress: (progress) => set({ progress }),
  resetTutor: () => set({ currentStep: 'input', studyPlan: null, progress: 0 })
});