import { logger } from '../../utils/logger';
import toast from 'react-hot-toast';

export function handleOpenAIError(error: any): Error {
  logger.error('OpenAI API error:', error);

  // Handle specific error cases
  if (error?.message?.includes('401') || error?.message?.includes('invalid_api_key')) {
    const message = 'OpenAI API key is not configured correctly. Please check your settings.';
    toast.error(message);
    return new Error(message);
  }

  if (error?.message?.includes('429')) {
    const message = 'Rate limit exceeded. Please try again in a moment.';
    toast.error(message);
    return new Error(message);
  }

  // Generic error handling
  const message = error?.message || 'Failed to generate quiz. Please try again.';
  toast.error(message);
  return new Error(message);
}