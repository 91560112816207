import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { QuizState } from './types';
import { createQuizSlice } from './slices/quiz.slice';

export const useQuizStore = create<QuizState>()(
  persist(
    createQuizSlice,
    {
      name: 'quiz-store',
      partialize: (state) => ({
        monthlyQuizCount: state.monthlyQuizCount,
        lastMonthReset: state.lastMonthReset,
      })
    }
  )
);