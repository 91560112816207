import OpenAI from 'openai';
import { config } from '../../config/env.config';
import { validateOpenAIKey } from './validation';
import { logger } from '../../utils/logger';

let openaiClient: OpenAI | null = null;

export function getOpenAIClient(): OpenAI {
  if (!openaiClient) {
    try {
      validateOpenAIKey(config.openai.apiKey);
      
      openaiClient = new OpenAI({
        apiKey: config.openai.apiKey,
        dangerouslyAllowBrowser: true
      });
      
      logger.debug('OpenAI client initialized');
    } catch (error) {
      logger.error('Error initializing OpenAI client:', error);
      throw error;
    }
  }
  return openaiClient;
}